import { lazy } from "react";
import i18next from "i18next";
import ExtLayout from "../layouts/ExtLayout";
import IntLayout from "../layouts/IntLayout";
import LoginLayout from "../layouts/LoginLayout";
import EmptyLayout from "../layouts/EmptyLayout";

export const ROLES = {
  admin: 'ADMIN',
  user: 'USER',
}

export const paths = {
  extHome: "/ext",
  extResults: "/ext/results",
  extResultsFiltered: "/ext/results/:name",
  extResultsPrinted: "/ext/results/print",
  extJobs: "/ext/jobs",
  extJobById: "/ext/job/:id",
  extAudit: "/ext/audit",
  extUsers: "/ext/users",
  intHome: "/int",
  intAudit: "/int/audit",

  login: "/login",
  logout: "/logout",
};

const ExtHome = lazy(() => import("../pages/Ext/Home")),
  ExtResults = lazy(() => import("../pages/Ext/Results")),
  //ExtResultsPrinted = lazy(() => import("../pages/Ext/Results/PrintResults")),
  ExtJobs = lazy(() => import("../pages/Ext/Jobs")),
  ExtJobById = lazy(() => import("../pages/Ext/JobById")),
  ExtAudit = lazy(() => import("../pages/Ext/Audit")),
  ExtUsers = lazy(() => import("../pages/Ext/Users")),
  IntHome = lazy(() => import("../pages/Int/Home")),
  IntAudit = lazy(() => import("../pages/Int/Audit")),
  Login = lazy(() => import("../pages/Login")),
  Logout = lazy(() => import("../pages/Logout"));

const createRoute = (
  pathname,
  component,
  layout,
  needRole = "",
  name = "",
  nameToDisplay = "",
  mainMenu = false,
  state = {}
) => {
  return {
    location: {
      pathname,
      state,
    },
    name,
    nameToDisplay,
    needRole,
    mainMenu,
    component,
    layout,
  };
};

const t = str => i18next.t(str);

export const ROUTES = [
  createRoute(paths.extHome, ExtHome, ExtLayout, ROLES.admin, "Home", t("Dashboard"), true),
  createRoute(paths.extResults, ExtResults, ExtLayout, ROLES.admin, "Results", t("Results"), true),
  //createRoute(paths.extResultsPrinted, ExtResultsPrinted, EmptyLayout, ROLES.admin, "Results", t("Results"), false),
  createRoute(paths.extResultsFiltered, ExtResults, ExtLayout, ROLES.admin, "Results", t("Results"), false),
  createRoute(paths.extJobs, ExtJobs, ExtLayout, ROLES.admin, "Jobs", t("Jobs"), true),
  createRoute(paths.extJobById, ExtJobById, ExtLayout, ROLES.admin, "Job", t("Job")),
  createRoute(paths.extAudit, ExtAudit, ExtLayout, ROLES.admin, "Audit", t("Audit Trail"), false),
  createRoute(paths.extUsers, ExtUsers, ExtLayout, ROLES.admin, "Users", t("User Management"), false),
  createRoute(paths.intHome, IntHome, IntLayout, ROLES.user, "Home", t("Search Dashboard"), true),
  createRoute(paths.intAudit, IntAudit, IntLayout, ROLES.user, "Audit", t("Audit Trail"), false),
  createRoute(paths.login, Login, LoginLayout),
  createRoute(paths.logout, Logout),
];
