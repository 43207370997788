import {put, select, takeEvery} from "redux-saga/effects";
import axios from "axios";
import config from "../../config/config";
import {authHeader} from "../../helpers/authHeader";
import JobsAction from "./jobsAction";
import RootScopeAction from "../rootScope/rootScopeAction";
import {apiJobsService, apiStatService} from "../../config/api";
import {selectJobFilter} from "./jobsSelector";
import i18next from "i18next";

function* getJobsListSaga(action) {
  yield put(JobsAction.setJobsLoading(true));

  try {
    const {page, size} = yield select(selectJobFilter);
    const data = yield apiJobsService.getJobs(page, size);
    console.log('data', data);
    yield put(JobsAction.setJobsList(data));
    yield put(JobsAction.setJobsLoading(false));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
    yield put(JobsAction.setJobsLoading(false));
  }
}

function* createJobSaga(action) {
  const { formData, cb } = action.payload;
  console.log('createJobSaga', formData);

  try {
    //yield axios.post(`${config.api_users}`, data, { headers: authHeader()})
    //yield apiJobsService.createJobUsingPOST1
    yield axios.post(`${config.api_jobs}/file`, formData, {
      headers: {
        ...authHeader(),
        'content-type': 'multipart/form-data'
      }
    });
    cb(true);
    yield put(JobsAction.setJobFilter({page: 0}));
    yield put(RootScopeAction.showNotification({
      type: "success",
      duration: 5000,
      text: i18next.t("Create Job"),
      description: i18next.t("Job created successfully and added to the queue")
    }));
  } catch (e) {
    cb(false);
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* createJobRangeSaga(action) {
  const { data, cb } = action.payload;
  console.log('createJobRangeSaga', data);

  try {
    yield apiJobsService.createJob(data);
    cb(true);
    //yield put(JobsAction.getJobsList({}));
    yield put(JobsAction.setJobFilter({page: 0}));
    yield put(RootScopeAction.showNotification({
      type: "success",
      duration: 5000,
      text: i18next.t("Create Job"),
      description: i18next.t("Job created successfully and added to the queue")
    }));
  } catch (e) {
    cb(false);
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* setStatusSaga(action) {
  const {jobId, status} = action.payload;

  try {
    yield apiJobsService.updateJobStatus(jobId, status);
    yield put(JobsAction.setJobStatus(jobId, status));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* getJobSaga(action) {
  yield put(JobsAction.setJobsLoading(true));
  try {
    const {data: currentJob} = yield apiJobsService.getJob(action.payload);
    yield put(JobsAction.updateJobsState({currentJob}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
  yield put(JobsAction.setJobsLoading(false));
}

function* getMainJobSaga() {
  yield put(JobsAction.setJobsLoading(true));
  try {
    const {data: currentJob} = yield apiJobsService.getMainJob();
    yield put(JobsAction.updateJobsState({currentJob}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
  yield put(JobsAction.setJobsLoading(false));
}

function* getJobCountries() {
  try {
    const {data: countries} = yield apiStatService.getCountryStat();
    yield put(JobsAction.updateJobsState({countries}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* getJobPhones(action) {
  try {
    console.log('action', action.payload);
    const {jobId, filters: {page = 0, size}, cb} = action.payload;
    const {data} = yield apiJobsService.getJobPhones(jobId, page, size);
    cb(data);
  } catch (e) {

  }
}

/*function* deleteJobSaga(action) {
  const { id } = action.payload;

  try {
    yield axios.delete(`${config.api_users}/${id}`, { headers: authHeader()})
    yield put(UsersAction.getUserList({}));
  } catch (e) {
    yield put(RootScopeAction.showNotification({
      text: e.message,
      type: "error",
      duration: 5000
    }));
  }
}*/

function* setJobFiltersSaga() {
  yield put(JobsAction.getJobsList());
}

export function* watchJobs() {
  yield takeEvery(JobsAction.GET_JOBS_LIST, getJobsListSaga);
  yield takeEvery(JobsAction.CREATE_JOB, createJobSaga);
  yield takeEvery(JobsAction.CREATE_JOB_RANGE, createJobRangeSaga);
  yield takeEvery(JobsAction.CALL_JOB_STATUS, setStatusSaga);
  yield takeEvery(JobsAction.GET_JOB, getJobSaga);
  yield takeEvery(JobsAction.GET_MAIN_JOB, getMainJobSaga);
  yield takeEvery(JobsAction.GET_JOB_COUNTRIES, getJobCountries);
  yield takeEvery(JobsAction.GET_JOB_PHONES, getJobPhones);
  yield takeEvery(JobsAction.SET_JOB_FILTER, setJobFiltersSaga);
  //yield takeEvery(JobsAction.DELETE_JOB, deleteJobSaga);
}
