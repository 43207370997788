import moment from "moment";
import i18next from "i18next";
import {SupportService} from "../../support";
import {capitalize} from "../../../helpers/capitalize";
import {Link} from "@react-pdf/renderer";
import React from "react";

const images = require.context('../../../assets/img/social', true);
const getIcon = (network) => {
  return images('./' + network.toLowerCase() + '.png');
}

class ExportHtml {
  constructor(data) {
    this.data = [];
    if (Array.isArray(data)) {
      this.rawData = [...data];
    } else {
      this.rawData = {...data};
    }
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }
  prepareProfileList() {
    let strProfiles = '';
    this.rawData.map(profile => {
      strProfiles += `
        <tr class="MuiTableRow-root WithStyles(ForwardRef(TableRow))-root-6">
          <td class="MuiTableCell-root MuiTableCell-body WithStyles(ForwardRef(TableCell))-body-5 MuiTableCell-sizeSmall">
              <span class="sc-pAncQ bQULPE">${profile.phone}</span>
          </td>
          <td class="MuiTableCell-root MuiTableCell-body WithStyles(ForwardRef(TableCell))-body-5 MuiTableCell-sizeSmall">
              <div class="sc-pIjat cMGIYy">${profile?.names?.[0] || ''}</div>
          </td>
          <td class="MuiTableCell-root MuiTableCell-body WithStyles(ForwardRef(TableCell))-body-5 MuiTableCell-sizeSmall">
              <div class="sc-qXRQq jVtbHC">
              ${profile.base64image ? '<img class="cbcgja" src="' + profile.base64image + '" alt="">' : ''}
              </div>
          </td>
          <td class="MuiTableCell-root MuiTableCell-body WithStyles(ForwardRef(TableCell))-body-5 MuiTableCell-sizeSmall">
            ${profile.sources.length > 0 ? '<img class="kzsTpF" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFgSURBVHgBnZOhUwJBFMa/twc4zniOZoJLMRmIJkdGmwH8AxwzFrU5FqTZJEk02JViRv6DzRrYYNcBlTng7rl7c8CJisAX7mbffr+373a+I4xJVtvbIMpTEBQAklFZMZECnLIuLuq4n4bgDa+Iz/cLJhxjghhUgdcr69PVt2EDC1Pno262s5hOir1+zjYRdmVPngG2ymIhWQonkNWOJO43MYfYR04I+KVpzGmXcLuXRHqJRkWH84L5/9EHsJsitLqjumlVsHfwrcGOdHC44fyAWx5w8NBDu8sxN8nE+GnEjPPNJJZTwN1zMAGO/JnrVjMWmFD76w4utxIh8NL+GzZnqYQp18y3nMQ37p/88L27Rjhr+L/CUQtFNrrEqGMOmTBlhC66jwyuYEZZxiRRh0mE55fNU83Aq4hB2MBm2mZ7mkmsZ/Af2DWNG+TVqxQppxQQZWmYEdb2so29po/cRtz/Bds7m9DXxBH4AAAAAElFTkSuQmCC" />' : ''}
          </td>
          <td class="MuiTableCell-root MuiTableCell-body WithStyles(ForwardRef(TableCell))-body-5 MuiTableCell-sizeSmall">
              <div class="sc-qPwPv kzsTpF">
              ${profile.socialNetworks.map(s => '<a target="_blank" title="' + s.socialNetwork + '" href="' + SupportService.getSocialUrl(s.socialNetwork, s.id) + '">' +
              '<img alt="" class="socialIcon" src="' + getIcon(s.socialNetwork) + '" />' +
              '</a>').join('')}
              </div>
          </td>          
          <td class="MuiTableCell-root MuiTableCell-body WithStyles(ForwardRef(TableCell))-body-5 MuiTableCell-sizeSmall">
              <div class="sc-qXRQq jVtbHC">
              ${profile.sources.map(source => '<img alt="" title="' + source + '" class="socialIcon" src="' + getIcon(source) + '" />').join('')}    
              </div>
          </td>
          <td class="MuiTableCell-root MuiTableCell-body WithStyles(ForwardRef(TableCell))-body-5 MuiTableCell-sizeSmall">
              <div class="rs-checkbox"></div>
          </td>
        </tr>                     
      `
    });

    this.data = `
<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN">
<html>
  <head>
  <meta http-equiv="content-type" content="text/html; charset=utf-8">  
    <style>
.MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
}
.MuiTable-root {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}
.MuiTableHead-root {
    display: table-header-group;
}
.MuiTableBody-root {
    display: table-row-group;
}
.MuiTableRow-root {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}
.WithStyles\\(ForwardRef\\(TableCell\\)\\)-head-4:first-child {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}
.WithStyles\\(ForwardRef\\(TableCell\\)\\)-head-4 {
    color: #777777;
    border: none;
    font-weight: 600;
    background-color: #FAFAFA;
}
.MuiTableCell-sizeSmall {
    padding: 6px 24px 6px 16px;
}
.WithStyles\\(ForwardRef\\(TableCell\\)\\)-head-4:last-child {
    display: table-cell;
    justify-content: center;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}
.MuiTableCell-sizeSmall:last-child {
    padding-right: 16px;
} 
.WithStyles\\(ForwardRef\\(TableRow\\)\\)-root-6:nth-of-type(even) {
    background-color: #FAFAFA;
}
.WithStyles\\(ForwardRef\\(TableRow\\)\\)-root-6 {
    height: 40px;
    border-bottom: none;
}
.WithStyles\\(ForwardRef\\(TableCell\\)\\)-body-5:first-child {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}
.WithStyles\\(ForwardRef\\(TableCell\\)\\)-body-5 {
    color: #777777;
    border: none;
    font-size: 14px;
}
.cbcgja {
    width: 36px;
    height: 36px;
    left: 830px;
    top: 195px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
}
.kzsTpF {
    
}   
    </style>
    </head>
    <body>
    <div class="sc-pQdCa cSjrtk">
      <div class="MuiTableContainer-root">
        <table class="MuiTable-root makeStyles-table-3" aria-label="customized table">
          <thead class="MuiTableHead-root">
            <tr class="MuiTableRow-root MuiTableRow-head">
                <th class="MuiTableCell-root MuiTableCell-head WithStyles(ForwardRef(TableCell))-head-4 MuiTableCell-sizeSmall" scope="col">${i18next.t('Phone')}</th>
                <th class="MuiTableCell-root MuiTableCell-head WithStyles(ForwardRef(TableCell))-head-4 MuiTableCell-sizeSmall" scope="col">${i18next.t('Name')}</th>
                <th class="MuiTableCell-root MuiTableCell-head WithStyles(ForwardRef(TableCell))-head-4 MuiTableCell-sizeSmall" scope="col">${i18next.t('Photo')}</th>
                <th class="MuiTableCell-root MuiTableCell-head WithStyles(ForwardRef(TableCell))-head-4 MuiTableCell-sizeSmall" scope="col">${i18next.t('Online')}</th>
                <th class="MuiTableCell-root MuiTableCell-head WithStyles(ForwardRef(TableCell))-head-4 MuiTableCell-sizeSmall" scope="col">${i18next.t('Networks')}</th>
                <th class="MuiTableCell-root MuiTableCell-head WithStyles(ForwardRef(TableCell))-head-4 MuiTableCell-sizeSmall" scope="col">${i18next.t('Sources')}</th>
                <th class="MuiTableCell-root MuiTableCell-head WithStyles(ForwardRef(TableCell))-head-4 MuiTableCell-sizeSmall" scope="col"></th>
            </tr>
          </thead>
          <tbody class="MuiTableBody-root">${strProfiles}</tbody>
        </table>
      </div>
  </div>
</body></html>`;
    return this;
  }
  prepareProfile() {
    this.fileName = "PROFILE_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    const {target, sources, base64images = []} = this.rawData;
    const { id, images = [], names = [], phone, socialNetworks = []} = target;
    const [image] = images;
    const [name] = names;
    const base64image = id => {
      return base64images.find(b => b.id === id)?.base64data;
    }
    sources.map(({lmSource, profile}) => {
      profile.socialMap = [];
      if (lmSource === 'DEEPWEB' && profile.other) {
        for (const [socialNetwork, entries = [] ] of Object.entries(profile.other)) {
          const socialMap = [];
          entries.map(entry => {
            socialMap.push({...entry, socialNetwork, phone})
          })
          profile.socialMap = socialMap;
        }
      }
    })

    let strProfiles = '';
    sources.map(({lmSource, profile}) => {
      strProfiles += `<div class="accordion-wrapper">
        <div class="accordion-title open">
            <div class="sc-AxjAm jhiTqR">
                <div class="sc-pcJja jYUFKt">
                    <img alt="${lmSource}" title="${lmSource}" src="${getIcon(lmSource)}">
                </div>
                <span>${capitalize(lmSource)}</span></div>
        </div>
        <div class="accordion-item ">
            <div class="accordion-content">
                <div class="sc-AxirZ fdNaAo">
                    <div class="sc-AxiKw icykSe">
                        <div class="sc-AxhCb irTIwL">${i18next.t('City')}</div>
                        <div class="sc-AxhUy eavbhP">${profile.city || '-'}</div>
                    </div>
                    <div class="sc-AxiKw icykSe">
                        <div class="sc-AxhCb irTIwL">${i18next.t('Country')}</div>
                        <div class="sc-AxhUy eavbhP">${profile.country || '-'}</div>
                    </div>
                    <div class="sc-AxiKw icykSe">
                        <div class="sc-AxhCb irTIwL">${i18next.t('Created')}</div>
                        <div class="sc-AxhUy eavbhP">${moment(profile?.createdTimestamp)?.format("Y.MM.DD HH:mm:ss")}</div>
                    </div>
                    <div class="sc-AxiKw icykSe">
                        <div class="sc-AxhCb irTIwL">Emails</div>
                        <div class="sc-AxhUy eavbhP">${profile?.emails?.join(', ') || '-'}</div>
                    </div>
                    <div class="sc-AxiKw icykSe">
                        <div class="sc-AxhCb irTIwL">${i18next.t('Names')}</div>
                        <div class="sc-AxhUy eavbhP">${profile?.names?.join(', ') || '-'}</div>
                    </div>
                    <div class="sc-AxiKw icykSe">
                        <div class="sc-AxhCb irTIwL">${i18next.t('Phone')}</div>
                        <div class="sc-AxhUy eavbhP">${profile.phone}</div>
                    </div>
                    <div class="sc-AxiKw icykSe">
                        <div class="sc-AxhCb irTIwL">${i18next.t('Last Online')}</div>
                        <div class="sc-AxhUy eavbhP">${moment(profile.lastOnline, 'x', true).isValid() ? moment(profile.lastOnline, 'x').format("Y.MM.DD HH:mm:ss") : profile.lastOnline || '-'}</div>
                    </div>
                    ${
                        profile.socialNetworks.map(({id, socialNetwork}) => 
                          '<div class="sc-AxiKw icykSe">' +
                          '<div class="sc-Axmtr cWXwVW">' +
                          '<div class="sc-pcJja jYUFKt">' +
                            '<img alt="' + socialNetwork + '" title="' + socialNetwork +  '" src="' + getIcon(socialNetwork) + '">' +
                          '</div>' +
                            '<a target="_blank" title="' + socialNetwork + '" href="' + SupportService.getSocialUrl(socialNetwork, id) + '">' + id + '</a>' +
                          '</div></div>'
                        ).join('')
                    }                    
                </div>
                ${
                  profile.socialMap.map(searchRes => `<hr><div>
                    <div class="">
                        <div class="sc-AxjAm jhiTqR">
                            <div class="sc-pcJja jYUFKt">
                                <img alt="" title="${searchRes.socialNetwork}" src="${getIcon(searchRes.socialNetwork)}">
                            </div>
                            <span><a target="_blank" title="${searchRes.socialNetwork}" href="${SupportService.getSocialUrl(searchRes.socialNetwork, searchRes.socialNetworkId)}">${searchRes.socialNetwork} : ${searchRes.socialNetworkId}</a></span></div>
                    </div>
                    <div class="sc-AxirZ fdNaAo">
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('First Name')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.firstName || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Last Name')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.lastNames?.join(', ')}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Maiden Name')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.maidenName || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Emails')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.emails?.join(', ')}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Phones')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.phones?.join(', ')}</div>
                      </div>
                      
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Addresses')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.addresses?.join(', ')}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Additional Contact')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.additionalContact || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Site')}</div>
                          <div class="sc-AxhUy eavbhP"><a target="_blank" src="${searchRes?.site}">${searchRes?.site || ''}</a></div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Birth Date')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.birthday || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Gender')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.gender || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Married')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.married || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Religion')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.religion || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Political')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.political || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Friends')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.friends || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('About')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.about || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('University')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.university || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Faculty')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.faculty || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Chair')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.chair || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('University Year End')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.universityYearEnd || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Work')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.work || '-'}</div>
                      </div>
                      <div class="sc-AxiKw icykSe">
                          <div class="sc-AxhCb irTIwL">${i18next.t('Schools')}</div>
                          <div class="sc-AxhUy eavbhP">${searchRes?.schools || '-'}</div>
                      </div>
                                          
                  </div>
                  </div>`).join('')
                }
                
                <div class="sc-AxheI jAHBQf">
                    ${profile.photos.map(photo => '<div>' +
                      '<img src="' + base64image(photo) + '" alt="' + profile.phone + '" class="sc-AxgMl dZIiNs">' +
                    '</div>')}                    
                </div>
            </div>
        </div>
    </div>`
    });

    this.data = `
    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>${phone}</title>
    <style>
.coGrBQ {
    padding: 20px 0;
}
.accordion-title {
    font-weight: 600;
    cursor: pointer;
    color: #666;
    padding: 0.5em 1.5em;
    border: solid 1px #ccc;
    border-radius: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.accordion-title:hover, .accordion-title.open {
    color: black;
}
.jhiTqR {
    display: flex;
    align-items: center;
}
.jYUFKt {
    margin: 0 5px 3px;
}
img {
    vertical-align: middle;
    border: 0;
}
.accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}
.accordion-content {
    padding: 1em 1.5em;
}
.fdNaAo {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
}
.icykSe {
    flex: 0 0 50%;
    display: flex;
}
.irTIwL {
    font-weight: bold;
    flex: 0 0 35%;
}
.eavbhP {
    flex: 0 0 65%;
}
.cWXwVW {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.jYUFKt {
    margin: 0 5px 3px;
}
.jAHBQf {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
.dZIiNs {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center center;
    margin: 2px;
    cursor: pointer;
}
.pZhuU {
    margin: 0 auto;
    text-align: center;
}
.ilFwHa {
    width: 290px;
    height: 290px;
    object-fit: cover;
    border-radius: 20px;
}
.iAsVVT {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color: #000000;
    padding: 10px 0;
}
.eMughl {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    opacity: 0.4;
}
.jqPeER {
    display: flex;
    margin: 10px 0;
    align-self: center;
    justify-content: center;
    width: 100%;
}

    </style>
</head>
<body>
<div class="sc-pTHAw pZhuU">
    ${image ? '<img class="sc-pkIrX ilFwHa" src="' + base64image(image) + '" alt="">' : ''}
    <div class="sc-ptdsS iAsVVT">${name || ''}</div>
    <div class="sc-qYsuA eMughl">${phone}</div>
    ${names.map(n => '<div class="sc-qYsuA eMughl">' + n + '</div>').join('')}    
    <div class="sc-qPXtF jqPeER">
    ${
      socialNetworks.map(({id, socialNetwork}) => '<a target="_blank" title="' + socialNetwork + '" href="' + SupportService.getSocialUrl(socialNetwork, id) + '">' +
              '<div class="sc-pcJja jYUFKt"><img alt="" class="socialIcon" src="' + getIcon(socialNetwork) + '" /></div>' +
              '</a>').join('')
    }   
    </div>
</div>
<div class="sc-pCPXO coGrBQ">${strProfiles}</div>
</body>
</html>`;

    return this.data;
  }
  export() {
    const elHtml = this.data;
    const mimeType = 'text/plain';
    const filename = this.fileName + '.html';

    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(new Blob([elHtml], { type: mimeType + ';charset=utf-8;' }), filename);
    } else {
      const link = document.createElement('a');
      link.setAttribute('download', filename);
      link.setAttribute('href', 'data:' + mimeType  +  ';charset=utf-8,' + encodeURIComponent(elHtml));
      link.click();
      link.remove();
    }

    return this;
  }
}

export default ExportHtml