import { createGlobalStyle } from "styled-components";
import { themes } from "../config/Themes";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${themes.regular.white} !important;
    color: ${themes.regular.report_text} !important;
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    min-height: 100vh;
    min-width: 320px;
  }

  a:hover {
    opacity: .7;
  }
  
  a:hover,
  a:visited,
  a:focus {
      text-decoration: none !important;
  }

  scrollbar-color: ${themes.regular.medium_grey} ${themes.regular.widget_border};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: ${themes.regular.widget_border};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${themes.regular.light_grey};
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${themes.regular.medium_grey};
  }
`