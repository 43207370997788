import BaseReducer from "../BaseReducer";
import JobsAction from "./jobsAction";
import config from "../../config/config";

export default class JobsReducer extends BaseReducer {
  initialState = {
    jobs: [],
    filters: {
      page: 0,
      size: config.jobPageSize,
    },
    pageCount: 0,
    currentPage: 0,
    loading: true,
    percent: 0,
    currentJob: null,
    countries: [],
  };

  [JobsAction.SET_JOBS_LIST](state, action) {
    return {
      ...state,
      jobs: action.payload.data
    };
  }

  [JobsAction.SET_JOB_STATUS](state, action) {
    const {jobId, status} = action.payload;
    return {
      ...state,
      jobs: {
        ...state.jobs,
        content: state.jobs.content.map(job => job.id === jobId ? { ...job, status } : job),
      }
    };
  }

  [JobsAction.SET_JOB_STATE](state, action) {
    const {jobId, jobData} = action.payload;
    return {
      ...state,
      jobs: {
        ...state.jobs,
        content: state.jobs.content.map(job => job.id === jobId ? { ...job, ...jobData } : job),
      }

    };
  }

  [JobsAction.SET_JOBS_LOADING](state, action) {
    return {
      ...state,
      loading: action.payload,
    };
  }

  [JobsAction.UPDATE_JOBS_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }

  [JobsAction.SET_JOB_FILTER](state, action) {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.payload
      },
    };
  }
}
