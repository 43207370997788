import ActionUtility from "../ActionUtility";

export default class JobsAction {
  static GET_JOBS_LIST = "JobsAction.GET_JOBS_LIST";
  static SET_JOBS_LIST = "JobsAction.SET_JOBS_LIST";
  static CREATE_JOB = "JobsAction.CREATE_JOB";
  static CREATE_JOB_RANGE = "JobsAction.CREATE_JOB_RANGE";
  static DELETE_JOB = "JobsAction.DELETE_JOB";
  static GET_JOB = "JobsAction.GET_JOB";
  static GET_MAIN_JOB = "JobsAction.GET_MAIN_JOB";

  static SET_JOBS_LOADING = "JobsAction.SET_JOBS_LOADING";
  static SET_JOBS_UPLOAD_PROGRESS = "JobsAction.SET_JOBS_UPLOAD_PROGRESS";

  static CALL_JOB_STATUS = "JobsAction.CALL_JOBS_STATUS";
  static SET_JOB_STATUS = "JobsAction.SET_JOBS_STATUS";
  static SET_JOB_STATE = "JobsAction.SET_JOBS_STATE";

  static GET_JOB_COUNTRIES = "JobsAction.CALL_JOBS_COUNTRIES";
  static GET_JOB_PHONES = "JobsAction.GET_JOB_PHONES";

  static UPDATE_JOBS_STATE = "JobsAction.UPDATE_JOBS_STATE";

  static SET_JOB_FILTER = "JobsAction.SET_JOB_FILTER";

  static getJobsList(data) {
    return ActionUtility.createAction(JobsAction.GET_JOBS_LIST, data);
  }

  static setJobsList(data) {
    return ActionUtility.createAction(JobsAction.SET_JOBS_LIST, data);
  }

  static createJob(formData, cb) {
    return ActionUtility.createAction(JobsAction.CREATE_JOB, {formData, cb});
  }

  static createJobRange(data, cb) {
    return ActionUtility.createAction(JobsAction.CREATE_JOB_RANGE, {data, cb});
  }

  static deleteJob(jobId) {
    return ActionUtility.createAction(JobsAction.DELETE_JOB, jobId);
  }

  static getJob(jobId) {
    return ActionUtility.createAction(JobsAction.GET_JOB, jobId);
  }

  static getMainJob() {
    return ActionUtility.createAction(JobsAction.GET_MAIN_JOB);
  }

  static setJobsLoading(state) {
    return ActionUtility.createAction(JobsAction.SET_JOBS_LOADING, state);
  }

  static setJobsUploadProgress(progress) {
    return ActionUtility.createAction(JobsAction.SET_JOBS_UPLOAD_PROGRESS, progress);
  }

  static callJobStatus(jobId, status) {
    return ActionUtility.createAction(JobsAction.CALL_JOB_STATUS, {jobId, status});
  }

  static setJobStatus(jobId, status) {
    return ActionUtility.createAction(JobsAction.SET_JOB_STATUS, {jobId, status});
  }

  static setJobState(jobId, jobData) {
    return ActionUtility.createAction(JobsAction.SET_JOB_STATE, {jobId, jobData});
  }

  static getJobCountries() {
    return ActionUtility.createAction(JobsAction.GET_JOB_COUNTRIES);
  }

  static getJobPhones(jobId, filters, cb) {
    return ActionUtility.createAction(JobsAction.GET_JOB_PHONES, {jobId, filters, cb});
  }

  static updateJobsState(state) {
    return ActionUtility.createAction(JobsAction.UPDATE_JOBS_STATE, state);
  }

  static setJobFilter(state) {
    return ActionUtility.createAction(JobsAction.SET_JOB_FILTER, state);
  }
}
