/**
 * This service implements functionality to make api calls through open api generated client
 * We are adding custom axios instance which helps customize the generated client with interceptors and more axios functionalities
 */
import {
  ApiToCheckHealthStatusOfAppApi, ApiToGetAuditApi,
  AuthControllerApi,
  JobAPIApi,
  ProfileAPIApi,
  UserAPIApi,
  StatAPIApi
} from "typescript-axios";
import axios from "axios";
import {authHeader} from "../helpers/authHeader";
import config from "../config/config";

// Create axios instance and update auth header each time
const axiosInstance = axios.create({ headers: authHeader()});
axiosInstance.interceptors.request.use(config => {
  config.headers = {...config.headers, ...authHeader()};
  //console.log('axios.config', config);
  return config;
});

// Configuration and base path are not provided
const basePath = config.api_base_path;
const apiAuthService = new AuthControllerApi(undefined, basePath, axiosInstance);
const apiJobsService = new JobAPIApi(undefined, basePath, axiosInstance);
const apiUsersService = new UserAPIApi(undefined, basePath, axiosInstance);
const apiHealthCheckerService = new ApiToCheckHealthStatusOfAppApi(undefined, basePath, axiosInstance);
const apiProfileService = new ProfileAPIApi(undefined, basePath, axiosInstance);
const apiAuditService = new ApiToGetAuditApi(undefined, basePath, axiosInstance);
const apiStatService = new StatAPIApi(undefined, basePath, axiosInstance);

export {
  apiAuthService,
  apiJobsService,
  apiUsersService,
  apiHealthCheckerService,
  apiProfileService,
  apiAuditService,
  apiStatService,
  axiosInstance
};
