import convert from "xml-js";
import moment from "moment";
import {imageByIndex} from "../../../helpers/imageByIndex";
import {SupportService} from "../../support";


class ExportDocx {
  constructor(data) {
    this.data = {
      _declaration: {
        _attributes: {
          version: "1.0",
          encoding: "utf-8"
        }
      },
      body: { raw : []}
    };
    this.rawData = Array.isArray(data) ? [...data] : {...data};
    this.fileName = 'export'
  }
  prepareProfileList() {
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.rawData.map(row => {
      row.images = row.images.map(image => imageByIndex(image));
      row.socialNetworks = row.socialNetworks.map(({id, socialNetwork}) => SupportService.getSocialUrl(socialNetwork, id));
      row.online = !!row.sources.length;
      delete row.id;
      delete row.timestamp;
      this.data.body.raw.push(row)
    })
    //console.log('this.rawData', this.rawData);
    return this;
  }
  prepareProfile() {
    this.fileName = "PROFILE_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    //console.log('this.rawData', this.rawData);

    const {sources, target} = this.rawData;

    delete target.id;
    delete target.timestamp;
    target.images = target.images.map(image => imageByIndex(image));
    target.socialNetworks = target.socialNetworks.map(({id, socialNetwork}) => SupportService.getSocialUrl(socialNetwork, id));

    sources.map(({lmSource, profile}) => {
      profile.photos = profile.photos.map(image => imageByIndex(image));
      profile.socialNetworks = profile.socialNetworks.map(({id, socialNetwork}) => SupportService.getSocialUrl(socialNetwork, id));
      profile.lastOnline = moment(profile.lastOnline, 'x', true).isValid()
        ? moment(profile.lastOnline, 'x').format("Y.MM.DD HH:mm:ss")
        : profile.lastOnline;
      delete profile.requestId;
      delete profile.id;
    })


    this.data.body.raw = {
      target,
      sources
    };

    return this;
  }

  export() {
    let options = {compact: true, ignoreComment: true, spaces: 4};
    let result = convert.json2xml(this.data, options);

    const url = window.URL.createObjectURL(new Blob([result], {type: 'text/xml'}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', this.fileName + ".xml");
    document.body.appendChild(link);
    link.click();

    return this;
  }
}

export default ExportDocx