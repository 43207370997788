import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import { getRootScope } from "../../../store/rootScope/rootScopeSelector";
import { ToastNotification } from "../Toasts";
import { Notification } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'
import {capitalize} from "../../../helpers/capitalize";
const container = document.getElementById("notifications");

/*const Notification = ({ notification }) => {
  const { addToast } = useToasts();

  useEffect(() => {
    notification &&
      notification.forEach((notification) => {
      addToast(notification.text, {
        appearance: notification.type,
        autoDismissTimeout: notification.duration,
      });
    });
  }, [notification, addToast]);

  return <div />;
};*/

/*export const Notifications = () => {
  const element = document.createElement("div");
  const { notification } = useSelector((state) => getRootScope(state));

  useEffect(() => {
    container.appendChild(element);
    return () => {
      container.removeChild(element);
    };
  }, [element]);

  return (
    notification.length > 0 &&
    createPortal(
      <ToastProvider
        autoDismiss={true}
        placement="bottom-right"
        components={{ Toast: ToastNotification }}
      >
        <Notification notification={notification} />
      </ToastProvider>,
      element
    )
  );
};*/
export const Notifications = () => {
  const { notification } = useSelector((state) => getRootScope(state));

  useEffect(() => {
    console.log('notification', notification);
    notification.map(note => {
      if (Notification[note.type]) {
        Notification[note.type]({
          title: note.text,
          duration: note?.duration,
          placement: 'bottomEnd',
          description: note.description,
        });
      }
    })
  }, [notification])

  return (
    <></>
  );
};
