import {put, takeEvery, takeLatest, select, call, take, fork, all} from "redux-saga/effects";
import RootScopeAction from "../rootScope/rootScopeAction";
import ProfilesAction from "./profilesAction";
import {apiProfileService} from "../../config/api";
import {
  selectProfilesFilter,
  selectProfilesFilterFiles, selectProfilesFilterListMode,
  selectProfilesFilterLists,
  selectSelectedFilters,
  selectSelectedPhones
} from "./profilesSelector";
import axios from "axios";
import config from "../../config/config";
import {authHeader} from "../../helpers/authHeader";
import ExportFactory from "../../services/Export/ExportFactory";
import base64ByIndex from "../../helpers/base64ByIndex";
import mockDeepWeb from "../../mock/deepweb";

function prepareFilters(filters, selectedFilters = []) {
  const preparedFilters = {...filters};
  ['name', 'phone', 'socialNetwork', 'email'].map(field => {
    if (filters[field]) {
      preparedFilters[field] = {
        value: [filters[field]],
        andFlag: selectedFilters.includes(field)
      }
    }
  });
  ['images'].map(field => {
    if (filters[field]) {
      preparedFilters[field] = {
        value: filters[field],
        andFlag: selectedFilters.includes(field)
      }
    }
  })
  return preparedFilters;
}
function prepareFileFilters(filters, selectedFilters = [], selectedFilterFiles = {}) {
  const preparedFilters = {...filters};
  ['name', 'phone', 'socialNetwork', 'email', 'images'].map(field => {
    const value = selectedFilterFiles?.[field]?.value;

    if (filters[field] || value) {
      preparedFilters[field] = {
        value: value ? value : [filters[field]],
        andFlag: selectedFilters.includes(field)
      }
    }
  })
  return preparedFilters;
}
function prepareListFilters(filters, selectedFilters = [], selectedFilterLists = {}) {
  const preparedFilters = {...filters};
  ['name', 'phone', 'socialNetwork', 'email', 'images'].map(field => {
    if (filters[field]) {
      delete preparedFilters[field]
    }
    const value = selectedFilterLists?.[field]?.value;
    if (value) {
      preparedFilters[field] = {
        value: value,
        andFlag: selectedFilters.includes(field)
      }
    }
  })
  return preparedFilters;
}

function prepareListResults(content = []) {
  content.map(profile => {
    if (profile.socialNetworks && profile.socialNetworks.length) {
      profile.socialNetworks = profile.socialNetworks.filter(({id, socialNetwork}) => id && socialNetwork);
      profile.names = profile.names.filter(n => n);
    }
  });
}

function prepareSingleResult(data) {
  //data.push(mockDeepWeb);
  data.map(({profile}) => {
    if (profile.socialNetworks && profile.socialNetworks.length) {
      profile.socialNetworks = profile.socialNetworks.filter(({id, socialNetwork}) => id && socialNetwork);
    }
  });
}

function* getProfilesListSaga(action) {
  yield put(ProfilesAction.setProfilesLoading(true));

  try {
    const filters = yield select(selectProfilesFilter);
    const selectedFilters = yield select(selectSelectedFilters);
    //const selectedFilterFiles = yield select(selectProfilesFilterFiles);
    const selectedFilterLists = yield select(selectProfilesFilterLists);
    const selectedFilterListMode = yield select(selectProfilesFilterListMode);

    let data;
    //if (Object.keys(selectedFilterLists).length) {
    if (selectedFilterListMode) {
      //@TODO: select and prepare appropriate api call here depends on selected file
      //const preparedFilters = prepareFileFilters(filters, selectedFilters, selectedFilterLists);
      const preparedFilters = prepareListFilters(filters, selectedFilters, selectedFilterLists);
      data = yield apiProfileService.searchProfiles(preparedFilters);
    } else {
      const preparedFilters = prepareFilters(filters, selectedFilters);
      data = yield apiProfileService.searchProfiles(preparedFilters);
    }

    prepareListResults(data?.data?.content);

    yield put(ProfilesAction.setProfilesList(data));
    yield put(ProfilesAction.setProfilesLoading(false));
    yield put(ProfilesAction.updateProfilesState({currentImageLoading: false}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
    yield put(ProfilesAction.setProfilesLoading(false));
  }
}

function* getProfileSaga(action) {
  yield put(ProfilesAction.updateProfilesState({loadingCurrentProfile: true, currentProfile: null}));

  try {
    let {data} = yield apiProfileService.getProfileByPhone(action.payload);
    prepareSingleResult(data);

    yield put(ProfilesAction.updateProfilesState({loadingCurrentProfile: false, currentProfile: data}));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
    yield put(ProfilesAction.updateProfilesState({loadingCurrentProfile: false}));
  }
}

function* setFiltersSaga() {
  yield put(ProfilesAction.getProfilesList());
  yield put(ProfilesAction.updateProfilesState({isFiltered: true}));
}

function* clearFiltersSaga() {
  yield put(ProfilesAction.getProfilesList());
  yield put(ProfilesAction.updateProfilesState({isFiltered: false}));
  yield put(ProfilesAction.clearSelectPhones());
}

function* searchByImageSaga(action) {
  const {file, filters} = action.payload;
  yield put(ProfilesAction.updateProfilesState({currentImageLoading: true, currentImageResults: null, imageProfiles: [], profiles: []}));
  yield put(ProfilesAction.setProfilesLoading(true));

  try{
    //const response = yield apiProfileService.searchByImageProfiles(action.payload, {'content-type': 'multipart/form-data'});
    const {data: response} = yield axios.post(`${config.api_profiles}/image/search`, file, {
      headers: {
        ...authHeader(),
        'content-type': 'multipart/form-data'
      }
    });
    yield put(ProfilesAction.updateProfilesState({currentImageLoading: false, currentImageResults: response}));
    const {faces = []} = response;
    const preparedIds = [];
    faces.map(({faceMatch}) => {
      if (faceMatch?.face?.externalImageId) {
        preparedIds.push(faceMatch.face.externalImageId);
      }
    });
    if (preparedIds.length) {
      //const {data: list} = yield apiProfileService.searchByImageIdProfiles(preparedIds);
      //yield put(ProfilesAction.updateProfilesState({imageProfiles: list}));
      //console.log('list', list);
      yield put(ProfilesAction.updateProfilesState({imageSearchDone: true}));
      yield put(ProfilesAction.setProfilesFilter({...filters, images: preparedIds, page: 0}))
    }
    yield put(ProfilesAction.setProfilesLoading(false));
  } catch (e) {
    yield put(RootScopeAction.handleAPIError(e));
    yield put(ProfilesAction.updateProfilesState({currentImageLoading: false}));
  }
}

function* exportProfilesListSaga(action) {
  try {
    const selectedPhones = yield select(selectSelectedPhones);
    let exportData;

    yield put(ProfilesAction.exportProfilesLoading(true));
    if (action.payload === 'json') {
      exportData = yield apiProfileService.exportProfiles(selectedPhones, {
        responseType: 'blob',
      });
    } else {
      const {data} = yield apiProfileService.getProfilesByPhone(action.payload, selectedPhones);
      if (action.payload === 'html') {
        //prepare images to view document offline
        const base64images = yield all(
          data.map(target => {
            const [image] = target.images;
            if (image) {
              return base64ByIndex(image);
            }
          })
        )
        data.map(target => {
          const [image] = target.images;
          if (image) {
            target.base64image = base64images.find(img => img?.id === image)?.base64data;
          }
        })
      }
      exportData = data;
      prepareListResults(exportData);
    }

    const exportInstance = yield ExportFactory.getExportHandler(action.payload, exportData);
    yield exportInstance.prepareProfileList();
    yield exportInstance.export();
    yield put(ProfilesAction.exportProfilesLoading(false));

  } catch (e) {
    yield put(ProfilesAction.exportProfilesLoading(false));
    yield put(RootScopeAction.handleAPIError(e));
  }
}

function* exportProfileSaga(action) {
  try {
    let {type, data} = action.payload;
    if (type === 'json') {
      data = yield apiProfileService.exportProfiles([data?.target?.phone], {responseType: 'blob'});
    } else /*if (type === 'html')*/ {
      //prepare images to view document offline
      const { target: {images = []} } = data;
      data.base64images = yield all(
        images.map(image => base64ByIndex(image))
      )
    }
    const exportInstance = yield ExportFactory.getExportHandler(type, data);
    yield exportInstance.prepareProfile();
    yield exportInstance.export();
  } catch (e) {
    console.log('e', e);
    yield put(RootScopeAction.handleAPIError(e));
  }
}


export function* watchProfiles() {
  yield takeLatest(ProfilesAction.GET_PROFILES_LIST, getProfilesListSaga);
  yield takeEvery(ProfilesAction.GET_PROFILE, getProfileSaga);
  yield takeEvery(ProfilesAction.SET_PROFILES_FILTER, setFiltersSaga);
  yield takeEvery(ProfilesAction.CLEAR_PROFILES_FILTER, clearFiltersSaga);
  yield takeEvery(ProfilesAction.SEARCH_BY_IMAGE, searchByImageSaga);
  yield takeEvery(ProfilesAction.EXPORT_PROFILES_LIST, exportProfilesListSaga);
  yield takeEvery(ProfilesAction.EXPORT_PROFILE, exportProfileSaga);
}